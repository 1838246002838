<template>
  <div class="container mt-5 mb-5">

    <div v-if="loading" class="d-flex justify-content-center mt-5 mb-5">
      <Preloader/>
    </div>

    <div v-else-if="rating" class="mb-2">

      <div v-if="+rating?.status_exam === 1">
        <h4 class="mb-2 text-center">{{student?.barcode}}</h4>
        <h4 class="mb-3 text-center">{{student?.last_name}} {{student?.first_name}} {{student?.middle_name}}</h4>
        <h5 class="mb-2 text-center">{{student?.studentGroups.name}}</h5>
        <h5 class="mb-4 text-center">{{student?.educationProgram?.education_speciality_code}}
          {{student?.educationProgram?.education_speciality_name}}</h5>

        <h3 class="text-center">
          Оценка выставлена: {{rating?.exam}}
        </h3>
      </div>

      <div v-else>
        <h4 class="text-center mb-4">Оценка за экзамен</h4>
        <form class="text-center" @submit.prevent="saveGrade">
          <div class="mb-4 d-flex justify-content-center">
            <input type="number" class="form-control form-control-lg" style="max-width: 130px"
                   min="0" max="100" required v-model="exam">
          </div>
          <button type="submit" class="btn btn-primary btn-lg mt-2" :disabled="saveGradeBtnDisabled||exam===null">
            <span v-if="saveGradeBtnDisabled" class="spinner-grow spinner-grow-sm" role="status"
                  aria-hidden="true"></span>
            {{ saveGradeBtnDisabled ? 'Сохранение' : 'Сохранить' }}
          </button>
        </form>
      </div>

    </div>
    <h3 v-else class="text-center">404</h3>

  </div>
</template>

<script>
  import httpClient from "../../services/http.service";
  import {getCookie} from "@/utils/helpers/cookies.helpers";

  export default {
    name: "Exam",
    data() {
      return {
        loading: true,
        ratingId: this.$route.params.id || 0,
        saveGradeBtnDisabled: false,
        exam: null,
        rating: null,
        student: null
      }
    },
    computed: {},
    methods: {
      async getRating() {
        try {
          const {
            status,
            data
          } = await httpClient.get(`student/student-ratings/get-rating-by-id?access-token=${getCookie('ACCESS_TOKEN')}&id=${this.ratingId}`);
          if (status === 200) {
            this.rating = data.rating
            this.student = data?.student
          }
        } catch (e) {
          console.log(e.response);
        }
      },
      async putExamGrade(putData) {
        try {
          const {
            status,
            data
          } = await httpClient.put(`student/student-ratings/update-student-ratings?access-token=${getCookie('ACCESS_TOKEN')}`, putData);
          if (status === 200 && data) {
            return true
          }
          return false
        } catch (e) {
          console.log(e.response);
          return false
        }
      },
      async saveGrade() {
        if (this.exam > 100 || this.exam < 0) {
          this.$error({text: 'Введите корректную оценку'})
          return
        }
        this.saveGradeBtnDisabled = true
        const res = await this.putExamGrade({student_rating_id: this.ratingId, exam: this.exam})
        if (res) {
          await this.getRating()
          this.exam = null
          this.$message({text: `Оценка за экзамен успешно выставлена`})
        } else {
          this.$error({text: 'Произошла ошибка'})
        }
        this.saveGradeBtnDisabled = false
      }
    },
    async mounted() {
      if (this.ratingId) {
        await this.getRating()
      }
      this.loading = false
    }
  }
</script>

<style scoped>

</style>